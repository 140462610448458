import { createContext } from "react";

export const AppContext = createContext();

// export const ProjectsContext = createContext();

// export const ProjectContext = createContext();

// export const BugContext = createContext();

// export const ProfilesContext = createContext();
