const { css } = require("styled-components");

const animations = css`
  /* @keyframes grow {
    0% {
      font-size: 0;
    }
    100% {
    }
  } */
`;

export default animations;
